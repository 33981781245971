import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { BreakoutRoomMessage } from "../../../types/CableMessage";
import { RoomsList, getRoomParticipants } from "./RoomsList";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { FormattedMessage } from "react-intl";
import { Button, LoadingButton } from "../ButtonV2/Button";
import { modalShow } from "../Modal/Modals";
import { Room } from "./Room";
import { MatchingCheckBox } from "./MatchingCheckBox";
import { Well } from "../Well/Well";
import postBreakoutSend from "../../../lib/requests/postBreakoutSend";
import toast from "react-simple-toasts";
import { obtainFilteredParticipants } from "./StartNewPage";

interface Props {
  rooms: BreakoutRoomMessage[];
  inSessionParticipants: Participant[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffeecc;
  border-radius: 9px;
  box-sizing: border-box;
  color: #000;
  width: 100%;
  margin-top: 5px;
  height: calc(100% - 70px);
`;

export const BigButton = styled(LoadingButton)`
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  height: 100%;

  &:hover {
    transform: scale(1.02);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 4px;
  gap: 5px;
`;

export const ConfigButton = styled(Button)`
  background: rgba(166, 77, 65, 0.5);

  font-size: 13px;
  padding: 10px;
  border-radius: 0px;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.selected {
    background: #a64d41;
    opacity: 1;
    font-weight: bold;
  }
`;

export const ConfigButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledRoom = styled.div`
  display: flex;
  flex-direction: row;

  border-radius: 9px;
  background-color: #fff;
  color: #000;
  max-width: 100%;
  position: relative;

  &.room-lobby {
    overflow-y: scroll;
  }

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
`;

export const ParticipantItem = styled.div`
  padding: 4px 7px;
  border-radius: 7px;
  background-color: #f0e5e0;
  width: fit-content;
  display: flex;
  align-items: center;

  &.offline {
    background-color: #ffbebe;
  }
`;

export const OfflineSign = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d96363;
  margin-right: 5px;
`;

const Rooms = styled.div`
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  padding-bottom: 40px;
  border-radius: 9px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: #dfc6a5;

  border-radius: 9px;
  padding: 10px;
  color: #000;

  h3 {
    padding: 0px;
    margin: 0px;
    font-size: 16px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: bold;
  border-radius: 9px;
  padding: 5px 15px;
`;

export function isInRoom(participant: Participant, room: BreakoutRoomMessage | undefined = undefined) {
  return (
    participant.location !== "offline" &&
    participant.location !== "dropped" &&
    (!room || participant.curRoomHashId === room.hashId)
  );
}

export const RoomsPage: FC<Props> = ({ rooms, inSessionParticipants }) => {
  const {
    selectedRoomsView,
    showAddParticipantToRoom,
    selectedRoom,
    allParticipants,
    locale,
    excludedParticipantIds,
    invitation,
  } = useStore(
    useShallow((state) => ({
      selectedRoomsView: state.selectedRoomsView,
      selectedRoom: state.selectedRoom,
      showAddParticipantToRoom: state.showModals.includes("AddParticipantToRoom"),
      allParticipants: state.allParticipants,
      excludedParticipantIds: state.excludedParticipantIds,
      locale: state.locale,
      invitation: state.invitation,
    })),
  );

  const [loadingSend, setLoadingSend] = useState(false);

  const activeRooms = rooms.filter((r) =>
    r.participantIds.find((p) => {
      const participant = allParticipants[p];

      if (!participant) {
        console.error(`[RoomsPage] Expected a room participant record. participantId: ${p}`, r);
      }

      return participant && participant.location == "session" && participant.curRoomHashId == r.hashId;
    }),
  );
  const inactiveRooms = rooms.filter((r) => !activeRooms.find((activeRoom) => activeRoom == r));

  const handleCloseAllRooms = () => {
    useStore.setState({
      selectedRoom: "allRooms",
    });
    modalShow("ConfirmCloseBreakout", false);
  };

  const storeScrollPosition = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollTop) {
      sessionStorage.setItem("roomsScrollPosition", target.scrollTop.toString());
    }
  };

  // Restore scroll position
  useEffect(() => {
    const target = document.getElementById("rooms-list") as HTMLDivElement;
    if (target) {
      target.scrollTop = Number(sessionStorage.getItem("roomsScrollPosition"));
    }
  }, [showAddParticipantToRoom]);

  const { allExcludedParticipantIds, allInSessionParticipantIds } = obtainFilteredParticipants(
    Object.values(inSessionParticipants),
    excludedParticipantIds,
  );
  const includeUserIds = allInSessionParticipantIds.filter((pid) => allExcludedParticipantIds.indexOf(pid) === -1);

  const handleSendToRoom = async (roomHashId: string) => {
    setLoadingSend(true);

    try {
      await postBreakoutSend({
        lobbyInvitationHashId: invitation.lobbyHashId,
        roomInvitationHashId: roomHashId,
        includeUserIds: includeUserIds,
      });
    } catch (e) {
      console.error(e);
      toast("Unable to send selected participants to breakout room. Please try again in a few minutes.");
    } finally {
      setLoadingSend(false);
    }
  };

  const numSelectedParticipants = inSessionParticipants.length - allExcludedParticipantIds.length;
  return (
    <Wrapper>
      {!showAddParticipantToRoom && (
        <>
          <Controls>
            <ConfigButtons>
              <ConfigButton
                className={selectedRoomsView === "ongoing" ? "selected" : ""}
                onClick={() => {
                  useStore.setState({ selectedRoomsView: "ongoing" });
                }}
              >
                <FormattedMessage
                  id="rooms_page.active"
                  defaultMessage="Active ({numRooms})"
                  values={{ numRooms: activeRooms.length }}
                />
              </ConfigButton>

              <ConfigButton
                className={selectedRoomsView === "closed" ? "selected" : ""}
                onClick={() => {
                  useStore.setState({ selectedRoomsView: "closed" });
                }}
              >
                <FormattedMessage
                  id="rooms_page.closed"
                  defaultMessage="Inactive ({numRooms})"
                  values={{ numRooms: inactiveRooms.length }}
                />
              </ConfigButton>
            </ConfigButtons>
            {selectedRoomsView === "ongoing" && (
              <StyledButton onClick={handleCloseAllRooms}>
                <FormattedMessage id="rooms_page.close_all_rooms" defaultMessage="Close All Rooms" />
              </StyledButton>
            )}
          </Controls>

          <Rooms onScroll={storeScrollPosition} id="rooms-list">
            {selectedRoomsView === "ongoing" && !showAddParticipantToRoom && <RoomsList rooms={activeRooms} />}
            {selectedRoomsView === "closed" && !showAddParticipantToRoom && <RoomsList rooms={inactiveRooms} />}

            {selectedRoomsView === "ongoing" && !showAddParticipantToRoom && activeRooms.length === 0 && (
              <EmptyState>
                <h3>
                  <FormattedMessage id="rooms_page.no_active_rooms" defaultMessage="No Active Rooms" />
                </h3>
                <FormattedMessage
                  id="roomsPage.emptyStateOngoing"
                  defaultMessage="There are no active rooms at the moment. Click 'Start New' above to start a new flow. "
                />
              </EmptyState>
            )}
            {selectedRoomsView === "closed" && !showAddParticipantToRoom && inactiveRooms.length === 0 && (
              <EmptyState>
                <FormattedMessage
                  id="roomsPage.emptyStateClosed"
                  defaultMessage="There are no closed rooms at the moment. Click 'Start New' above to start a new flow."
                />
              </EmptyState>
            )}
          </Rooms>
        </>
      )}

      {showAddParticipantToRoom && selectedRoom && (
        <>
          <Well style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
            <Room
              locale={locale}
              key={selectedRoom}
              room={rooms.find((r) => r.hashId === selectedRoom) as BreakoutRoomMessage}
              idx={1}
              getRoomParticipants={getRoomParticipants}
              allParticipants={allParticipants}
              showBackNavigation={true}
            />
          </Well>

          <MatchingCheckBox participants={inSessionParticipants} />

          <ButtonWrapper>
            <BigButton
              disabled={numSelectedParticipants <= 0}
              loading={loadingSend}
              onClick={() => handleSendToRoom(selectedRoom)}
            >
              <FormattedMessage
                id="roomsPage.addParticipantsToRoom"
                defaultMessage="Add {numParticipants} Participants to Room"
                values={{ numParticipants: numSelectedParticipants }}
              />
            </BigButton>
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};
