import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { toastConfig } from "react-simple-toasts";

import { useHandleNoSleep } from "../../hooks/useHandleNoSleep";
import { Prejoin } from "../Prejoin/Prejoin";
import { VideoSession } from "../VideoSession/VideoSession";
import { InPersonSession } from "../InPersonSession/InPersonSession";
import { useStore } from "../../core/store";
import { useShallow } from "zustand/react/shallow";
import Music from "../UI/Music/Music";
import { useGoToFeedback } from "../../hooks/useGoToFeedback";
import WaitingForPartner from "../UI/WaitingForPartner/WaitingForPartner";
import { ConnectionIssueBadge } from "../UI/Badge/ConnectionIssueBadge";
import { useSetDeviceClassification } from "../../hooks/useSetDeviceClassification";
import { dropParticipant } from "../../core/SessionChannel/dropParticipant";
import DropParticipantModal from "../UI/DropParticipantModal/DropParticipantModal";
import Menu from "../UI/Menu/Menu";
import ManageParticipantsModal from "../UI/Menu/ManageParticipantModal";
import { LobbyStatusBadge } from "../UI/Badge/LobbyStatusBadge";
import { Chime } from "../UI/Chime/Chime";
import { ConfirmCloseRoomModal } from "../UI/FlowsModal/ConfirmCloseRoomModal";
import { QrCodeModal } from "../UI/QR/QrCodeModal";
import { ConfirmReturnRoomModal } from "../UI/FlowsModal/ConfirmReturnRoomModal";
import { PrejoinInPerson } from "../Prejoin/PrejoinInPerson";
import { ConfirmLeaveModal } from "../UI/ConfirmLeaveModal/ConfirmLeaveModal";
import { EndScreen } from "../UI/EndScreen/EndScreen";
import { ReportPartnerModal } from "../UI/ReportPartnerModal/ReportPartnerModal";
import { ReportPartnerEndScreen } from "../UI/ReportPartnerEndScreen/ReportPartnerEndScreen";

toastConfig({
  theme: "dark",
  className: "toast",
  offsetY: 70,

  clickClosable: true,
  maxVisibleToasts: 1,
});

const AppWrapper = styled.div`
  height: 100%;

  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  padding: 5px 5px 0px 5px;
  box-sizing: border-box;

  &.breakoutAvailable {
    height: calc(100% - 35px);
    margin-top: 35px;
  }
`;

const App: FC = () => {
  console.debug("[session-ui]: App render");

  const {
    mode,
    inPerson,
    participants,
    iframeVisible,
    sessionConsumerIsConnected,
    showDropParticipantModal,
    showManageParticipantsModal,
    showConfirmCloseBreakoutModal,
    showConfirmLeaveModal,
    showReturnRoomModal,
    showReportPartnerModal,
    sessionEndDate,
    invitation,
    currentUser,
    core,
  } = useStore(
    useShallow((state) => ({
      mode: state.mode,
      inPerson: state.inPerson,
      participants: state.participants,
      iframeVisible: state.iframeVisible,
      sessionConsumerIsConnected: state.sessionConsumerIsConnected,
      showDropParticipantModal: state.showDropParticipantModal,
      showManageParticipantsModal: state.showModals.includes("ManageParticipants"),
      currentStep: state.currentStep,
      showConfirmCloseBreakoutModal: state.showModals.includes("ConfirmCloseBreakout"),
      showReturnRoomModal: state.showModals.includes("ConfirmReturnRoom"),
      showConfirmLeaveModal: state.showModals.includes("ConfirmLeave"),
      showReportPartnerModal: state.showModals.includes("ReportPartner"),
      sessionEndDate: state.sessionEndDate,
      currentUser: state.currentUser,
      invitation: state.invitation,
      core: state.core,
    })),
  );

  const isSomeonePresent = core.isSomeonePresent;
  const isLobby = invitation.lobby;
  const isWaitingForPartner = !invitation.solo && !isSomeonePresent && !iframeVisible && !isLobby;

  useHandleNoSleep();
  useGoToFeedback();
  useSetDeviceClassification();

  const joinedParticipants = useMemo(() => {
    return participants && Object.values(participants).filter((x) => x?.joined);
  }, [participants]);

  const breakoutRoomHashId = participants[currentUser.id]?.breakoutRoomHashId;
  const isBreakoutRoom = breakoutRoomHashId === invitation.hashedID;

  return (
    <AppWrapper className={mode === "session" && (breakoutRoomHashId || isLobby) ? "breakoutAvailable" : ""}>
      <Music />
      <Chime />
      <ConnectionIssueBadge isConnected={sessionConsumerIsConnected} />

      {mode === "session" && (
        <LobbyStatusBadge title={invitation.title || ""} inRoom={isBreakoutRoom} expirationDate={sessionEndDate} />
      )}

      {mode === "prejoin" && !inPerson && <Prejoin />}
      {mode === "prejoin" && inPerson && <PrejoinInPerson />}
      {mode === "session" && inPerson && !isWaitingForPartner && <InPersonSession />}
      {mode === "session" && !inPerson && <VideoSession isWaitingForPartner={isWaitingForPartner} />}
      {mode === "end" && <EndScreen />}
      {mode === "reportPartner" && <ReportPartnerEndScreen />}

      {showDropParticipantModal > -1 && (
        <DropParticipantModal dropParticipant={dropParticipant.bind(undefined, showDropParticipantModal)} />
      )}

      {showConfirmCloseBreakoutModal && <ConfirmCloseRoomModal />}
      {showReturnRoomModal && <ConfirmReturnRoomModal />}
      {showConfirmLeaveModal && <ConfirmLeaveModal inRoom={isBreakoutRoom} />}
      {showReportPartnerModal && <ReportPartnerModal />}

      {showManageParticipantsModal && (
        <ManageParticipantsModal
          joinedParticipants={joinedParticipants}
          dropParticipant={dropParticipant}
          yourId={currentUser.id}
        />
      )}
      <QrCodeModal />

      {mode === "session" && isWaitingForPartner && (
        <WaitingForPartner
          acceptedTime={"unknown"} // TODO
          partnersGettingReady={
            Object.values(participants)
              .filter((x) => x.id !== currentUser.id)
              .filter((x) => x.location == "ready-room").length
          }
          partnersInSession={
            Object.values(participants)
              .filter((x) => x.id !== currentUser.id)
              .filter((x) => x.location == "session").length
          }
          partnerCount={Object.values(participants).filter((x) => x.id !== currentUser.id).length}
          parentInvitationID={invitation.parentInvitationID}
          invitationAutoGroup={invitation.autoGroup}
          invitationID={invitation.id}
          invitationHashedID={invitation.hashedID}
        />
      )}

      {mode === "session" && <Menu />}
    </AppWrapper>
  );
};

export default App;
