import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { CheckBox } from "../Modal/ModalStyle";
import { CheckMarkIcon, Close } from "../../../assets/icons/Icons";
import { obtainFilteredParticipants } from "./StartNewPage";

interface Props {
  participants: Participant[];
}

export const Header = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-top: -10px;
  margin-bottom: -10px;
`;

const ParticipantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  border-radius: 9px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 10px;
  overflow-y: scroll;
  height: 100%;
`;

const StyledLabel = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const ParticipantRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MultipleSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;

  &.select-all {
    gap: 10px;
    margin-right: 3px;
  }
`;

export const MatchingCheckBox: FC<Props> = ({ participants }) => {
  const { excludedParticipantIds } = useStore(
    useShallow((state) => ({
      excludedParticipantIds: state.excludedParticipantIds,
    })),
  );

  const { allExcludedParticipantIds } = obtainFilteredParticipants(participants, excludedParticipantIds);

  return (
    <>
      <Header>
        <FormattedMessage
          description="Text for electing participants to include in matching when starting a new breakout room"
          id="start_new_breakout.includeInMatching"
          defaultMessage="Participants ({count}):"
          values={{ count: participants.length - allExcludedParticipantIds.length }}
        />
        {allExcludedParticipantIds.length > 0 ? (
          <MultipleSelect
            className="select-all"
            onClick={() => {
              useStore.setState({ excludedParticipantIds: [] });
            }}
          >
            <FormattedMessage
              description="Select all participants"
              id="start_new_breakout.selectAll"
              defaultMessage="Select All"
            />
            <CheckMarkIcon stroke="#000" />
          </MultipleSelect>
        ) : (
          <MultipleSelect
            onClick={() => {
              useStore.setState({ excludedParticipantIds: participants.map((p) => p.id) });
            }}
          >
            <FormattedMessage
              description="Clear all participants from selection"
              id="start_new_breakout.clearAll"
              defaultMessage="Clear All"
            />
            <Close stroke="#000" width="20px" height="20px" />
          </MultipleSelect>
        )}
      </Header>
      <ParticipantsWrapper>
        {participants.length === 0 && (
          <small>
            <FormattedMessage
              description="Matching Checkbox text detailing there are no participants available to match"
              id="matching_check_box.noParticipants"
              defaultMessage="No participants available to match"
            />
          </small>
        )}
        {participants.map((participant) => {
          return (
            <ParticipantRow key={participant.id}>
              <StyledLabel key={participant.id}>
                <CheckBox
                  style={{ marginRight: "10px" }}
                  type="checkbox"
                  name={participant.name}
                  value={participant.name}
                  checked={!excludedParticipantIds.includes(participant.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      useStore.setState({
                        excludedParticipantIds: excludedParticipantIds.filter((pid) => pid != participant.id),
                      });
                    } else {
                      excludedParticipantIds.push(participant.id);
                      useStore.setState({ excludedParticipantIds: [...excludedParticipantIds] });
                    }
                  }}
                />
                {participant.name}
              </StyledLabel>
            </ParticipantRow>
          );
        })}
      </ParticipantsWrapper>
    </>
  );
};
