import { Core } from "../core";
import { showLoading } from "../../lib/hideLoading";
import { useStore } from "../store";
import { determineContent } from "../determineContent";
import CableMessage, {
  BreakoutRoomMessage,
  isAudioCommand,
  isBreakoutRoomMessage,
  isBreakoutRoomsMessage,
  isChatMessage,
  isDropUserCommand,
  isGoToUrlMessage,
  isInvitationResponse,
  isMuteAllMessage,
  isMuteParticipantMessage,
  isPresencesMessage,
  isReloadCommand,
  isRoomClosingMessage,
  isToastMessage,
  isToggleHandMessage,
  isToggleRecordingMessage,
  isUpdateMessage,
} from "../../types/CableMessage";
import toast from "react-simple-toasts";
import { replacePlaceholder } from "../../lib/getFeedbackURL";
import { Rooms } from "../../types/Rooms";
import { onAudioMessage } from "./onAudioMessage";
import { isContentStep } from "../../types/type_guards";
import { SFX } from "../../assets/SFX";
import { Locales } from "../intl";

const SYSTEM_USER_ID = -1;

export const onMessage = async function (this: Core, u: CableMessage) {
  console.debug("[session-ui]: received websocket message:", u);
  const core = Core.GetInstance();

  // If the server is sending us somewhere, then go there
  if (isGoToUrlMessage(u)) {
    window.location.href = replacePlaceholder(u.go_to_url, {
      hashedInvitationID: this.hashedInvitationID,
      yourID: this.currentUser.id,
    });
    return;
  }

  // If we're transitioning, then save the presences message for later processing
  if (useStore.getState().transitioning && isPresencesMessage(u)) {
    if (u.delta) {
      const newPresencesMessages = [...useStore.getState().unprocessedPresencesMessages];
      newPresencesMessages.push(u);
      useStore.setState({ unprocessedPresencesMessages: newPresencesMessages });
    } else {
      useStore.setState({ unprocessedPresencesMessages: [u] });
    }
  } else if (isPresencesMessage(u)) {
    const oldRooms = useStore.getState().rooms;
    const roomMap: Rooms = {};

    // Create a map of the rooms and re-add all the participants to create new underlying arrays
    oldRooms.forEach((oldRoom) => {
      const oldRoomCopy = Object.assign({}, oldRoom);
      oldRoomCopy.participantIds = [...oldRoom.participantIds];
      roomMap[oldRoom.hashId] = oldRoomCopy;
    });

    const allParticipants: Participants = Object.assign({}, useStore.getState().allParticipants);
    const curParticipants: Participants = u.delta ? Object.assign({}, useStore.getState().participants) : {};
    let roomsUpdated = false;

    Object.keys(u.presences).forEach((userIdString) => {
      const userID = parseInt(userIdString);
      const presence = u.presences[userID];

      const participant = {
        dailySessionID: presence.dailySessionID,
        id: userID,
        authorizations: presence.authorizations,
        joined: true,
        kicked: false,
        location: presence.location,
        name: presence.name,
        breakoutRoomHashId: presence.breakoutRoomHashId,
        curRoomHashId: presence.hashedInvitationID,
        personalCode: presence.personalCode,
      };

      allParticipants[userID] = participant;

      // If the user is not in this invitation or have been dropped, then delete them from the curParticipants
      if (presence.hashedInvitationID != this.hashedInvitationID || presence.location === "dropped") {
        delete curParticipants[participant.id];
      }
      // Otherwise add them to the curParticipants
      else {
        curParticipants[participant.id] = participant;
      }

      // If the presence update indicates additional people in a breakout room, then add them now
      const curRoom = roomMap[participant.curRoomHashId];
      if (curRoom && curRoom.participantIds.indexOf(participant.id) == -1) {
        curRoom.participantIds.push(participant.id);
        roomsUpdated = true;
      }
    });

    // Check if the current user is in a breakout room
    const breakoutRoomHashId = allParticipants[core.currentUser.id]?.breakoutRoomHashId;
    const isBreakoutRoom = breakoutRoomHashId === core.invitation.hashedID;

    core.isSomeonePresent = Object.values(curParticipants)
      .filter((x) => x.id !== core.currentUser?.id)
      .some((x) => x.location === "session" || (x.dailySessionID && !useStore.getState().inPerson));

    playSoundJoinedOrLeft(core, curParticipants);

    const newRooms = Object.values(roomMap);
    if (roomsUpdated) {
      newRooms.sort((roomA, roomB) => roomB.updatedAt.localeCompare(roomA.updatedAt));
    }

    useStore.setState({
      allParticipants: allParticipants,
      participants: curParticipants,
      isBreakoutRoom: isBreakoutRoom,
      rooms: roomsUpdated ? newRooms : oldRooms,
    });

    if (useStore.getState().mode == "session" && allParticipants[core.currentUser.id].location != "session") {
      this.subscription?.perform("update_location", { location: "session" });
    }
  }

  // Find the latest room that completed their session and use them as the basis for showing the analytics
  function updateBreakoutRooms(rooms: BreakoutRoomMessage[]) {
    rooms.sort((roomA, roomB) => roomB.updatedAt.localeCompare(roomA.updatedAt));

    const completedRooms = rooms.filter((room) => room.showAnalytics && room.status === "completed");
    completedRooms.sort((roomA, roomB) => roomB.updatedAt.localeCompare(roomB.updatedAt));
    const reportHashId = core.invitation.lobbyReportHashId;
    const latestRoom = completedRooms[0];
    const sequenceInstanceHashId = latestRoom?.sequenceInstanceHashId;
    const cacheKey = latestRoom?.updatedAt;
    const isLobby = core.invitation.lobby;
    const newExternalContentUrl =
      !isLobby || !sequenceInstanceHashId
        ? null
        : core.buildExternalContentUrl(reportHashId, sequenceInstanceHashId, cacheKey);

    let roomsUpdated = false;
    useStore.setState((state) => {
      if (state.rooms?.length == rooms.length) {
        for (let i = 0; i < rooms.length; i++) {
          const newRoom = rooms[i];
          const oldRoom = state.rooms[i];
          if (newRoom.hashId != oldRoom.hashId || newRoom.updatedAt != oldRoom.updatedAt) {
            roomsUpdated = true;
            break;
          }
        }
      } else {
        roomsUpdated = true;
      }

      const existingExternalContentUrl = state.externalContentUrl;
      const showExternalContent = state.showExternalContent;

      const newShowExternalContent: boolean =
        isLobby &&
        // Either keep showing analytics or show it now that a new url is available
        (showExternalContent ||
          (existingExternalContentUrl != newExternalContentUrl &&
            sequenceInstanceHashId !== undefined &&
            // Only start showing analytics if the current user participated in the latest session
            rooms.find(
              (r) =>
                r.sequenceInstanceHashId == sequenceInstanceHashId && r.participantIds.includes(core.currentUser.id),
            ) != null));

      return {
        // If no room was updated, then don't update the state rooms to avoid reloading analytics.
        rooms: roomsUpdated ? rooms : state.rooms,
        externalContentUrl: newExternalContentUrl || existingExternalContentUrl,
        showExternalContent: newShowExternalContent,
      };
    });
  }

  if (isBreakoutRoomsMessage(u)) {
    updateBreakoutRooms(u.rooms);
  } else if (isBreakoutRoomMessage(u)) {
    const oldRooms = useStore.getState().rooms;
    const newRooms = oldRooms.filter((room) => room.hashId != u.hashId);
    newRooms.push(u);
    updateBreakoutRooms(newRooms);
  }

  if (isChatMessage(u)) {
    if (u.chat.user_id === this.currentUser.id || useStore.getState().showChat) {
      useStore.getState().addChatMessage({ ...u.chat, read: true });
    } else {
      useStore.getState().addChatMessage(u.chat);

      const chatMessage = u.chat.message;
      const toastMessage = chatMessage.length < 50 ? chatMessage : chatMessage.substring(0, 50) + "...";
      toast(`${u.chat.name}: ${toastMessage}`);
    }
  }

  if (isUpdateMessage(u)) {
    // If we're on a new step, reset the iframeVisible state.
    const currentStep = useStore.getState().currentStep;
    if (
      (currentStep && currentStep.id !== u.current_step.id) ||
      useStore.getState().roleData[Core.GetInstance().currentUser.id]?.role !==
        u.role_data[Core.GetInstance().currentUser.id]?.role
    ) {
      // Keep showing the iframe unless the step changed, or keep hiding it if it was already hidden
      const iframeVisible = useStore.getState().iframeVisible && currentStep?.id === u?.current_step?.id;
      useStore.setState({ iframeVisible: iframeVisible });
    }

    const locale = (core.invitation.locale as Locales) || core.locale;
    const stepContent = determineContent(
      u.current_step,
      u.user_responses,
      u.role_data,
      core.currentUser.id,
      core.intl,
      locale,
      core.invitation.flowData,
      core.invitation.hashedID,
    );

    const narrating = u.current_step_data[SYSTEM_USER_ID]?.narrating === true;

    let audioObject = useStore.getState().currentAudioObject;

    if (isContentStep(u.current_step) && u.current_step.audioFile) {
      console.debug(`found step content for locale: ${locale}, content: ${stepContent?.text}`, stepContent);

      const audioFile = u.current_step.audioFile[this.locale]?.url || u.current_step.audioFile["en"]?.url || "";
      const narrationAudio = useStore.getState().narrationAudio;

      if (!narrationAudio[audioFile]) {
        if (audioObject) {
          audioObject.pause();
          audioObject = undefined;
        }
      } else {
        audioObject = narrationAudio[audioFile];
      }
    } else {
      if (audioObject) {
        audioObject.pause();
        audioObject = undefined;
      }
    }

    // Calculate how long we've been on this step already
    const systemStepData = u.current_step_data["-1"];

    // If the local time is more than 5 seconds off from the server time, then use the server
    // time because the local machine time is probably out of sync with the actual time
    const serverNow = new Date(u.now + "+00:00").getTime();
    const localNow = new Date().getTime();
    const now = Math.abs(serverNow - localNow) > 5000 ? serverNow : localNow;

    const startTime = new Date(systemStepData.startTime + "+00:00").getTime();
    const endTime = new Date(systemStepData.endTime + "+00:00").getTime();
    const timeOnStep = systemStepData ? (now - startTime) / 1000 : useStore.getState().timeOnStep;
    const stepTiming = Math.round((endTime - startTime) / 1000);

    useStore.setState((state) => ({
      audioProgress: u.audio_progress,
      currentStep: u.current_step,
      currentAudioObject: audioObject,
      roleData: u.role_data,
      stepContent: stepContent,
      stepData: u.current_step_data,
      lastStep: u.last_step,
      showAddPartner: u.step_index == 0 && u.flow_index == 0,
      personalCode: u.personal_code,
      narrating: narrating,
      stepTiming: stepTiming,

      // Only update timeOnStep if the difference between the derived step time and the counted time is more than 1 sec
      timeOnStep: Math.abs(state.timeOnStep - timeOnStep) < 1 ? state.timeOnStep : Math.round(timeOnStep),
    }));

    // If this user has been assigned a new invitation, send them there now
    const newHashedInvitationID = u.invitation_assignment && u.invitation_assignment[this.currentUser.id];
    if (newHashedInvitationID != this.hashedInvitationID) {
      console.debug("[session-ui]: invitationAssignment: ", u.invitation_assignment);

      this.joinNewInvitation(newHashedInvitationID);

      // Mark the state as transitioning so that we don't process any presences updates for 2 seconds
      // to allow video connections to remain intact when transitions from one flow to another
      useStore.setState({ transitioning: true });
      setTimeout(() => {
        const unprocessedPresencesMessages = useStore.getState().unprocessedPresencesMessages;
        useStore.setState({
          transitioning: false,
          unprocessedPresencesMessages: [],
        });
        if (unprocessedPresencesMessages.length > 0) {
          unprocessedPresencesMessages.forEach((upm) => onMessage.bind(Core.GetInstance())(upm));
        }
      }, 1500);
    }
  }

  if (isDropUserCommand(u)) {
    // The actual removing of the user is handled by the presence message
    toast(`${u.user_name} dropped ${u.dropped_user_name}`);
  }

  if (isReloadCommand(u)) {
    if (u.in_person && !useStore.getState().inPerson) {
      // Switch from digital to physical
      await this.callObject?.leave();

      useStore.setState({ inPerson: true });
      if (useStore.getState().mode === "prejoin") {
        this.subscription?.perform("update_location", { location: "session" });
        useStore.setState({ mode: "session" });
      }
      this.renderApp();
    }

    if (!u.in_person && useStore.getState().inPerson) {
      // Switch from physical to digital

      useStore.setState({ mode: "loading", inPerson: false });
      showLoading();

      this.joinNewInvitation(this.invitation.hashedID);
    }
  }

  if (isMuteAllMessage(u)) {
    if (u.muterId !== this.currentUser.id) {
      core.callObject?.setLocalAudio(false);
    }
    toast(`${u.mutedBy} muted all participants.`);
  }

  if (isMuteParticipantMessage(u)) {
    const mutedParticipantName = useStore.getState().allParticipants[u.participantId]?.name;

    if (u.participantId === this.currentUser.id) {
      core.callObject?.setLocalAudio(false);
    }

    if (mutedParticipantName) {
      toast(`${u.mutedBy} muted ${mutedParticipantName}.`);
    } else {
      toast(`${u.mutedBy} muted a participant.`);
    }
  }

  if (isAudioCommand(u)) {
    console.debug("[onMessage]: audio command received", u.audio_command, u.user_name, u.time, u.skip_toast);
    onAudioMessage.bind(this)(u);
  }

  if (isRoomClosingMessage(u)) {
    const state = useStore.getState();
    const oldEndDate = state.sessionEndDate;
    const oldClosingType = state.sessionClosingType;

    // Only show an update if
    // a) there is no old end time
    // b) the new and old end times are more than a second apart.
    // c) it's a different type of closing message
    if (
      !oldEndDate ||
      oldClosingType != u.closingType ||
      u.closingType == "estimate" ||
      new Date(u.closingTime + "+00:00").getTime() + 1000 < oldEndDate.getTime()
    ) {
      const newEndDate = new Date(u.closingTime + "+00:00");
      console.debug(
        `[onMessage] transitioning room at: ${newEndDate}, curTime: ${new Date()}, transitionType: ${u.closingType}`,
      );

      // Disable these messages for now since we're auto-advancing steps and people don't need to manage their own time anymore
      // const msUntilEnd = newEndDate.getTime() - new Date().getTime();
      // const secondsRemaining = Math.trunc(msUntilEnd / 1000);
      // const minutesRemaining = Math.trunc(secondsRemaining / 60);
      // let closingMessage: string;
      // switch (u.closingType) {
      //   case "feedback":
      //     // We're not doing extra time for feedback anymore
      //     // closingMessage = "Ending with feedback";
      //     closingMessage = "Session ending";
      //     break;
      //   case "lobby":
      //     closingMessage = "Returning to lobby";
      //     break;
      //   case "closing":
      //     closingMessage = "Session ending";
      //     break;
      // }
      //
      // if (minutesRemaining > 5) {
      //   toast(`${closingMessage} in ${minutesRemaining} minutes`);
      // } else if (secondsRemaining <= 90) {
      //   toast(`${closingMessage} in ${secondsRemaining} seconds`);
      // } else {
      //   const secondsPosition = secondsRemaining % 60;
      //   const secondsString = secondsPosition >= 10 ? secondsPosition : `0${secondsPosition}`;
      //   toast(`Session is ending in ${minutesRemaining}:${secondsString} minutes`);
      // }
      useStore.setState({
        sessionEndDate: newEndDate,
        sessionClosingType: u.closingType,
      });
    }
  }

  if (isToggleHandMessage(u)) {
    const state = useStore.getState();

    let newRaisedHands: number[] | null = null;
    let actionName: string | null = null;

    const toggledByParticipant = state.allParticipants[u.toggledByParticipantId];
    const toggledParticipant = state.allParticipants[u.participantId];

    const newParticipantsLastActiveDates = Object.assign({}, useStore.getState().participantsLastActiveDates);

    if (u.action === "raise_hand" && !state.raisedHands.includes(u.participantId)) {
      newRaisedHands = [...state.raisedHands];
      newRaisedHands.push(u.participantId);
      actionName = "raised";

      newParticipantsLastActiveDates[toggledParticipant.id] = new Date();
    } else if (u.action === "lower_hand" && state.raisedHands.includes(u.participantId)) {
      newRaisedHands = state.raisedHands.filter((participantId) => participantId != u.participantId);
      actionName = "lowered";
    }

    if (actionName) {
      if (u.toggledByParticipantId != u.participantId) {
        toast(`${toggledByParticipant.name} ${actionName} ${toggledParticipant.name}'s hand`);
      } else {
        toast(`${toggledParticipant.name} ${actionName}  their hand`);
      }

      useStore.setState({
        participantsLastActiveDates: newParticipantsLastActiveDates,
        raisedHands: newRaisedHands!,
      });
    }
  }

  if (isToggleRecordingMessage(u)) {
    const state = useStore.getState();

    let currentActionName: string = "recording & transcribing";
    if (state.recording && state.transcribing) {
      currentActionName = "recording & transcribing";
    } else if (state.recording) {
      currentActionName = "recording";
    } else if (state.transcribing) {
      currentActionName = "transcribing";
    }

    let actionMessage: string | null = null;
    switch (u.action) {
      case "start_recording":
        actionMessage = "started recording";
        break;
      case "stop_recording":
        actionMessage = "stopped " + currentActionName;
        break;
      case "start_transcription":
        actionMessage = "started transcription";
        break;
      case "start_recording_and_transcription":
        actionMessage = "started recording & transcription";
        break;
    }
    const toggledByName = u.toggledByParticipantId == core.currentUser.id ? "You" : u.toggledByParticipantName;

    if (u.errorMessage) {
      toast(u.errorMessage);
      useStore.setState({
        recordingLoading: false,
      });
    } else {
      toast(toggledByName + " " + actionMessage);
      useStore.setState({
        recording: u.action == "start_recording" || u.action == "start_recording_and_transcription",
        transcribing: u.action == "start_transcription" || u.action == "start_recording_and_transcription",
        recordingLoading: false,
      });

      if (SFX.recordingStarted) {
        SFX.recordingStarted.volume = 0.1;
        SFX.recordingStarted.play().catch((e) => {
          console.error("Failed to play recordingStarted sound", e);
        });
      }
    }
  }

  if (isToastMessage(u)) {
    toast(u.toastMessage);
  }

  if (isInvitationResponse(u)) {
    const invitationResolver = Core.GetInstance().invitationResolver;
    invitationResolver && invitationResolver(u);
  }
};

function playSoundJoinedOrLeft(core: Core, newParticipants: Participants) {
  // Play a partnerArrived/partnerDisconnected if a participant joined or left
  const oldParticipants = useStore.getState().participants;
  const oldParticipantsList: Participant[] = Object.values(oldParticipants).filter(
    (p) => p.location == "session" && p.id != core.currentUser.id,
  );

  // Only play a sound if there are at least 3 participants present and if we're over video (not in person)
  if (oldParticipantsList.length <= 3 && !core.invitation.inPerson) {
    console.debug("participant sound: checking if someone joined or left");
    const newParticipantsList: Participant[] = Object.values(newParticipants).filter(
      (p) => p.location == "session" && p.id != core.currentUser.id,
    );
    let participantJoined = false;
    for (const newParticipant of newParticipantsList) {
      const oldParticipant = oldParticipants[newParticipant.id];
      if (!oldParticipant || oldParticipant.location != "session") {
        participantJoined = true;
      }
    }
    if (participantJoined) {
      if (SFX.partnerArrived) {
        console.debug("participant sound: arrived");
        SFX.partnerArrived.pause();
        SFX.partnerArrived.currentTime = 0;
        SFX.partnerArrived.play().catch((e) => {
          console.error("Failed to play partnerConnected sound", e);
        });
      }
    } else {
      for (const oldParticipant of oldParticipantsList) {
        const newParticipant = newParticipants[oldParticipant.id];
        if (!newParticipant || newParticipant.location != "session") {
          if (SFX.partnerDisconnected) {
            console.debug("participant sound: left");
            SFX.partnerDisconnected.pause();
            SFX.partnerDisconnected.currentTime = 0;
            SFX.partnerDisconnected.play().catch((e) => {
              console.error("Failed to play partnerDisconnected sound", e);
            });
          }
          break;
        }
      }
    }
  }
}
