import React, { FC, useEffect, useState } from "react";
import { SFX } from "../../../assets/SFX";
import { useShallow } from "zustand/react/shallow";
import { useStore } from "../../../core/store";
import { isContentStep, isFeedbackScoreStep, isFeedbackWordsStep } from "../../../types/type_guards";

function playChime(setChimePlayed: (value: ((prevState: boolean) => boolean) | boolean) => void) {
  setChimePlayed(true);
  const timeChime = SFX.timeChime;
  if (timeChime) {
    const unmutedAudios = Array.from(document.getElementsByTagName("audio")).filter((a) => !a.muted);
    unmutedAudios.forEach((a) => (a.muted = true));

    // Try playing the chime a little later to give the system to process the muted audios
    setTimeout(() => {
      timeChime.volume = 0.2;
      timeChime.pause();
      timeChime.currentTime = 0;
      timeChime
        .play()
        .catch(() => {
          console.debug("Failed to play time chime");
        })
        .then(() => {
          console.debug("Un-muting daily.co audio");
          unmutedAudios.forEach((a) => (a.muted = false));
        });
    }, 30);
  }
}

export const Chime: FC = () => {
  const { currentStep, roleData, currentUser, invitation, timeOnStep, stepTiming } = useStore(
    useShallow((state) => ({
      currentStep: state.currentStep,
      invitation: state.invitation,
      roleData: state.roleData,
      currentUser: state.currentUser,
      timeOnStep: state.timeOnStep,
      stepTiming: state.stepTiming,
    })),
  );

  const isChimedStep =
    isContentStep(currentStep) || isFeedbackScoreStep(currentStep) || isFeedbackWordsStep(currentStep);

  let role = roleData[currentUser.id]?.role;
  if (isContentStep(currentStep) && currentStep.shareContent) {
    role = "EVERYONE_ELSE";
  }

  const [chimePlayed, setChimePlayed] = useState(false);

  // If a video session, then play chimes at the start of every step so people notice they need to switch roles
  useEffect(() => {
    if (isChimedStep && invitation.playChimes && !invitation.inPerson) {
      playChime(setChimePlayed);
    }
  }, [currentStep?.id, role]);

  // If an in-person session, then play chimes 2 sec before the end of each step so people notice they're out of time
  if (invitation.inPerson && !chimePlayed && stepTiming && invitation.playChimes && timeOnStep >= stepTiming - 2) {
    playChime(setChimePlayed);
  }

  return <></>;
};
